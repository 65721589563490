import { Stage, StageSize } from "@alphaneun/ui-library";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";

export function DefaultStage() {
    const data = useStaticQuery(graphql`
            query DefaultStageImageQuery {
                stageimage: file(relativePath: { eq: "index-stage.jpg" }) {
                    ...fullSizeImage
                }
            }
        `),
        image = getImage(data.stageimage);
    return (
        <Stage
            text="Hi"
            size={StageSize.Small}
            image={
                <GatsbyImage
                    alt=""
                    style={{ height: "100%" }}
                    // @ts-ignore
                    image={image}
                    loading="eager"
                ></GatsbyImage>
            }
        />
    );
}
