import { Container, ContainerSize, Headline } from "@alphaneun/ui-library";
import { PageProps } from "gatsby";
import React from "react";
import { DefaultStage } from "../components/DefaultStage";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

import Layout from "../components/Layout";

const NotFound: React.FC<PageProps> = () => (
    <Layout>
        <DefaultStage />

        <Container size={ContainerSize.Default}>
            <main>
                <Headline>404</Headline>
                <p>Sorry, page not found!</p>
            </main>
        </Container>
    </Layout>
);

export default withPrismicUnpublishedPreview(NotFound);
